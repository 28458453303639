<template>
  <div class="page">
    <div class="page-title">Модерация предложенных проектов</div>

    <div class="page-content">
      <div class="card" v-for="project in selfProjects">
        <div class="info-block">
          <h4>Контактная информация</h4>
          <div>Автор: {{ project.user?.name }} {{ project.user?.surname }}</div>
          <div>Email: {{ project.user?.email }}</div>
        </div>
        <div class="info-block" lang="ru">
          <h4>О проекте</h4>
          <div>Создан: {{ new Date(project.createdAt).toLocaleString() }}</div>

          <div>Название: {{ project.name }}</div>
          <div>Описание: {{ project.description }}</div>
          <div>Результат: {{ project.result }}</div>
          <div>Эффект: {{ project.effect }}</div>
        </div>
        <div class="moderation-options">
          <div class="option">
            <button @click="accept(project.id)">
              Принять проект
            </button>
            <el-select
              v-model="SectionSelected"
              class="RDSelectField m-2"
              placeholder="Select"
              size="large"
              style="min-width: 300px;"
            >
              <el-option
                v-for="item in SectionsList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <el-select
              v-model="DifficultySelected"
              class="RDSelectField m-2"
              placeholder="Select"
              size="large"
              style="min-width: 300px;"
            >
              <el-option
                v-for="item in DifficultyLevels"
                :key="item.difficult"
                :label="item.name"
                :value="item.difficult"
              />
            </el-select>
          </div>

          <div class="option">
            <button @click="decline(project.id)">
              Отклонить проект
            </button>
            <textarea
              v-model="comment"
              rows="4"
              placeholder="Комментарий отказа"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllSelfProjects,
  getAllDifficulties,
  updateJob,
} from "@/api/job.js";
import { getSections } from "@/api/competence.js";

export default {
  name: "self-project-modearation",
  data() {
    return {
      selfProjects: [],
      showAcceptOptions: false,
      showDeclineOptions: false,

      DifficultySelected: 3,
      DifficultyLevels: [],

      SectionSelected: "",
      SectionsList: [],

      comment: "",
    };
  },
  created() {
    this.getProjects();

    getSections().then((response) => {
      let sections = response.data;
      let competence = [];

      let self = this;

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((x) => x.id),
            };
          });
        competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });

      if (competence.length >= 1) {
        self.SectionsList = competence[0].CBlock;

        if (competence[0].CBlock.length > 0) {
          self.SectionSelected = competence[0].CBlock[0].id;
        }
      }
    });

    getAllDifficulties().then((response) => {
      this.DifficultyLevels = response.data;
      this.DifficultySelected =
        response.data != null && response.data.length != 0
          ? response.data[0]["difficult"]
          : 0;
    });
  },
  methods: {
    getProjects() {
      getAllSelfProjects().then(
        (res) =>
          (this.selfProjects = res.data.filter(
            (self_project) =>
              self_project.status === "moderation" &&
              self_project.authorId !== null
          ))
      );
    },
    accept(project_id) {
      let findDifficultIndex = this.DifficultyLevels.findIndex(
        (obj) => obj.difficult === this.DifficultySelected
      );

      let findSectionIDFromList = this.SectionsList.findIndex(
        (obj) => obj.id === this.SectionSelected
      );
      let LevelsArray = this.SectionsList[findSectionIDFromList].levels;
      LevelsArray = LevelsArray.filter((num, i) => {
        if (i < this.DifficultySelected) {
          return num;
        }
      });

      updateJob(project_id, {
        levels: LevelsArray,
        status: null,
        level: this.DifficultyLevels[findDifficultIndex]["name"],
        sections: [this.SectionSelected],
      }).then(() => this.getProjects());
    },
    decline(project_id) {
      updateJob(project_id, {
        comment: this.comment,
        status: "declined",
      }).then(() => this.getProjects());
    },
  },
};
</script>

<style scoped>
.moderation-options {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.option {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}

textarea {
  border-radius: 0.25rem;
  border: 1px solid #413e74;
  background: linear-gradient(
    269deg,
    rgba(41, 37, 88, 0.54) 4.06%,
    rgba(38, 35, 83, 0.87) 42.35%,
    rgba(40, 38, 89, 0.5) 99.53%
  );
  backdrop-filter: blur(12.253599166870117px);

  display: flex;
  width: 60%;
  padding: 0.8125rem 0.75rem 0.75rem 0.75rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: #fff;
}
button {
  display: flex;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  background: #413e74;
  color: var(--white, #fff);

  /* text */
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 1.45313rem */
  min-width: 15rem;
  border: 0;

  margin-right: 0.5rem;
  margin-bottom: auto;
  margin-top: auto;
}
button:hover {
  background: #33315c;
}
.info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  hyphens: auto;
  hyphens: auto;
  text-align: justify;
  word-break: break-all;
  width: 90%;
}
.card {
  display: inline-flex;
  padding: 1.875rem 0rem 1.83069rem 1.8125rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.875rem;
  width: 80%;

  border-radius: 1.25rem;
  background: rgba(31, 30, 72, 0.6);

  color: #fff;
}

.page {
  width: 75%;
  height: fit-content;

  display: flex;
  margin: 6.25rem auto;

  flex-direction: column;
}

.page-title {
  color: #e8e7ec;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  justify-content: flex-start;
}

.page-content {
  margin-top: 3rem;
  width: 100%;
}
</style>
